<template>
  <aside
    v-show="isAsideVisible"
    class="aside is-placed-left"
    :class="{'is-expanded':isAsideExpanded}"
  >
    <div
      ref="container"
      class="aside-container"
      @mouseenter="psUpdate"
    >
      <div
        class="image"
        :class="{'fadeIn animated':hasFadeIn}"
      >
        <img src="../assets/jblogo-icon.png">
      </div>
      <div
        class="menu is-menu-main fast"
        :class="{'fadeIn animated':hasFadeIn}"
        @animationend="hasFadeIn = false"
      >
        <aside-menu-list
          :menu="menu"
          @menu-click="menuClick"
        />
      </div>
    </div>
  </aside>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
// import PerfectScrollbar from 'perfect-scrollbar'
import AsideMenuList from '@/components/AsideMenuList.vue'

export default defineComponent({
  name: 'AsideMenu',
  components: { AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      hasFadeOut: false,
      hasFadeIn: false
    }
  },
  computed: {
    hasAnimated () {
      return this.hasFadeOut || this.hasFadeIn
    },
    ...mapState([
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded'
    ])
  },
  watch: {
    isAsideExpanded () {
      this.hasFadeIn = true
    }
  },
  mounted () {
    // this.ps = new PerfectScrollbar(this.$refs.container)
  },
  methods: {
    menuClick (item) {
      if (this.ps && item && item.menu) {
        this.ps.update()
      }
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    }
  }
})
</script>
