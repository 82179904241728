<template>
  <div>
    <aside-updates-item
      v-for="item in items"
      :key="item.id"
      :status="item.status"
      :icon="item.icon"
      :ago="item.ago"
    >
      <p>{{ item.text }}</p>
    </aside-updates-item>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'
import { mapState } from 'vuex'
import AsideUpdatesItem from '@/components/AsideUpdatesItem.vue'

export default defineComponent({
  name: 'AsideUpdates',
  components: { AsideUpdatesItem },
  data () {
    return {
      items: []
    }
  },
  computed: {
    ...mapState([
      'isAsideUpdatesVisible'
    ])
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      axios
        .get('/data-sources/updates.json')
        .then(r => {
          this.items = r.data.data
          this.$emit('data-updated')
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger'
          })
        })
    }
  }
})
</script>
