<template>
  <div id="app">
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view />
    <aside-right />
    <footer-bar />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import AsideRight from '@/components/AsideRight.vue'

export default defineComponent({
  components: { AsideRight, FooterBar, AsideMenu, NavBar },
  computed: {
    menu () {
      return [
        {
          to: '/',
          icon: 'desktop-mac',
          label: 'Dashboard'
        },
        {
          to: '/tables',
          label: 'Tables',
          icon: 'table',
          updateMark: true
        },
        {
          to: '/forms',
          label: 'Forms',
          icon: 'square-edit-outline'
        },
        {
          to: '/profile',
          label: 'Profile',
          icon: 'account-circle'
        },
        {
          to: '/login',
          label: 'Login',
          icon: 'lock'
        },
        {
          to: '/error-in-card',
          label: 'Error v.1',
          icon: 'power-plug'
        },
        {
          to: '/error-simple',
          label: 'Error v.2',
          icon: 'alert-decagram'
        },
        {
          to: '/lock-screen',
          label: 'Lock Screen',
          icon: 'lock-reset'
        },
        {
          href: 'https://justboil.me/bulma-admin-template/two',
          label: 'Buy theme',
          icon: 'credit-card',
          target: '_blank'
        },
        {
          label: 'Submenus',
          subLabel: 'Submenus Example',
          icon: 'view-list',
          menu: [
            {
              href: '#void',
              label: 'Sub-item One',
              icon: 'menu'
            },
            {
              href: '#void',
              label: 'Sub-item Two',
              icon: 'menu'
            }
          ]
        }
      ]
    }
  },
  created () {
    this.$store.commit('user', {
      name: 'John Doe',
      email: 'john@example.com',
      avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93'
    })
  },
  mounted () {
    document.documentElement.classList.remove('has-spinner-active')
  }
})
</script>
